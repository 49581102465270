<template>
  <div>
    <helloWorld />
    <div class="content-1020 order-box">
      <div style="max-width:600px;margin:auto">
        <el-steps :active="1" finish-status="success" simple style="margin-top: 20px">
          <el-step title="拍下藏品" ></el-step>
          <el-step title="付款" ></el-step>
          <el-step title="交易成功" ></el-step>
        </el-steps>
      </div>
      <div>
        <div class="confirm-title">确认订单信息</div>
        <div class="phone-order" style="margin-top:20px">
          <div class="buy-item">
            <div class="buy-key">数字藏品：</div>
            <div>
              <img :src="collectInfo.pic" style="width:70px;object-fit: cover;" alt="">
              <div class="buy-val">{{collectInfo.colName}}</div>
            </div>
          </div>
          <div class="buy-item" style="margin-top10px;margin-bottom:15px">
            <div class="buy-key">原创作者：</div>
            <div class="buy-val">{{creator.nickname}}</div>
          </div>
          <div class="buy-item" style="justify-content:space-between">
            <div class="buy-item" style="margin:0">
              <div class="buy-key">价 格：</div>
              <div class="buy-val">￥{{collectInfo.price}}</div>
            </div>
            <div class="buy-item" style="margin:0">
              <div class="buy-key">数 量：</div>
              <div class="buy-val">1</div>
            </div>
          </div>
          
        </div>
        <div class="all-shop" style="margin-top:20px">
          <div class="ant-table-wrapper">
            <div class="ant-spin-nested-loading">
              <div class="ant-spin-container">
                <div class="ant-table ant-table-scroll-position-left ant-table-default">
                  <div class="ant-table-content">
                    <div class="ant-table-body">
                      <table class="">
                        <colgroup>
                          <col style="width: 200px; min-width: 200px;"><col>
                          <col>
                            <col>
                        </colgroup>
                        <thead class="ant-table-thead">
                          <tr>
                            <th key="name" class="ant-table-row-cell-break-word">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">数字藏品</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th key="userName" class="">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">原创作者</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th key="price" class="">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">价格</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th key="quantity" class="ant-table-row-cell-last">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">数量</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="ant-table-tbody">
                          <tr class="ant-table-row ant-table-row-level-0" data-row-key="1312674">
                            <td class="ant-table-row-cell-break-word"><div data-v-27156c7e="" class="arts-line-box">
                              <div data-v-27156c7e="" class="pic">
                                <img :src="collectInfo.pic" style="width:70px;object-fit: cover;">
                              </div>
                              <div data-v-27156c7e="" class="des"><div data-v-27156c7e="" class="text">
                                {{collectInfo.colName}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="">{{creator.nickname}}</td>
                        <td class="">
                          <span data-v-27156c7e="" style="color: rgb(227, 22, 41);">￥{{collectInfo.price}}</span>
                        </td>
                        <td class="">
                          1
                        </td>
                      </tr>
                    </tbody>
                  </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div data-v-27156c7e="" class="pay-methods">
          <div data-v-27156c7e="" class="word"> 支付方式:</div>
          <el-radio v-model="radio" label="1">微信支付</el-radio>
        </div> -->
        <div class="totalprice">
          <div class="price">
            <span class="price-text">总价：</span>￥{{collectInfo.price}}
          </div>
        </div>
        <div style="text-align:right;margin:6px 0">
          <el-button type="primary" style="padding:10px 30px" @click="toPay">付款</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import {getForm, toOrder} from '../../api/index'
import { mapGetters } from 'vuex'

export default {
  components: {
    helloWorld
  },
  data() {
    return {
      env: process.env.VUE_APP_BASE_API,
     radio: '1',
     colCode: '',
     collectInfo: {},
     creator: {},
     paymentList: []
    }
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.colCode = this.$route.query.colCode
    this.getDetail()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  methods: {
    // 付款
    toPay() {
      let obj = {
        colCode: this.colCode
      }
      toOrder(obj).then(res => {
        if (res.code == 0) {
          let obj = {
            colCode: res.data.colCode,
            orderCode: res.data.orderCode
          }
          let str = JSON.stringify(obj);
          this.$router.push({ path: "./pay", query: { str } })
        } else {
          this.$message.error(res.message)
        }
        // let payObj = {
        //   colCode: res.data.colCode,
        //   orderCode: res.data.orderCode,
        //   realPay: res.data.realPay
        // }
        // payCollect(payObj).then(Response => {
        //   console.log(Response)
        // })
      })
    },
    getDetail() {
      let obj = {
        colCode: this.colCode
      }
      getForm(obj).then(res => {
        if (res.code == 0) {
          this.collectInfo = res.data.assetCollect
          this.collectInfo.pic = this.env+this.collectInfo.pic
          this.creator = res.data.assetCollect.creator
          this.paymentList = res.data.paymentList
        }else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style scoped>
.buy-key {
  color: rgba(0,0,0,.65);
  font-size: 14px;
  letter-spacing: 1px;
}
.buy-val {
  color: rgba(0,0,0,.65);
    font-size: 14px;
    letter-spacing: 1px;
}
.buy-item {
  display:flex;
  align-items:center;
  margin: 10px 20px;
}
.totalprice {
    width: 100%;
    text-align: right;
    margin-top: 20px;
}
.totalprice .price {
    font-size: 18px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #409EFF;
    line-height: 40px;
}
.totalprice .price .price-text {
    color: #1d202b;
}
.content-1020 {
  max-width: 1020px;
  margin: auto;
  padding: 50px 0;
}
.confirm-title {
  font-size: 16px;
    width: 171px;
    padding: 10px 0 20px 0;
    text-align: center;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #303030;
    line-height: 22px;
    letter-spacing: 1px;
    border-bottom: 2px solid #2f54eb;
    margin: 20px 0;
}
.arts-line-box {
    display: flex;
    align-items: center;
}
.ant-table-wrapper {
    zoom: 1;
}
.ant-table-wrapper:before {
    display: table;
    content: "";
}
.ant-spin-nested-loading {
    position: relative;
}
.ant-spin-container {
    position: relative;
    transition: opacity .3s;
}
.ant-table {
    border: 1px solid #e5e8eb;
    border-bottom: none;
}
.ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    clear: both;
}
.ant-table-body {
    transition: opacity .3s;
}
.ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}
.ant-table .ant-table-thead>tr>th {
    background: #fff;
}
.ant-table-thead>tr:first-child>th:first-child {
    border-top-left-radius: 4px;
}
.ant-table-thead>tr>th .ant-table-header-column {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
}
.payment-box .order-result .all-shop[data-v-27156c7e] .ant-table .ant-table-column-title {
    font-size: 12px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #8e929c;
}
.ant-table-thead>tr>th .ant-table-column-sorter {
    display: table-cell;
    vertical-align: middle;
}
.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
    padding: 8px 16px;
    overflow-wrap: break-word;
}
.ant-table-tbody>tr>td {
    border-bottom: 1px solid #e8e8e8;
    transition: background .3s;
}
.payment-box .order-result .all-shop .arts-line-box {
  display: flex;
    align-items: center;
}
.img-style {
    width: 60px;
    height: 60px;
}
.des{
    margin-left: 20px;
}
.des .text {
    height: 34px;
    font-size: 12px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #1d202b;
    line-height: 17px;
    width: 200px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.pay-methods{
  margin-top: 50px;
    width: 100%;
    border-bottom: 1px solid #e5e8eb;
    padding-bottom: 15px;
}
.pay-methods .word {
    font-size: 14px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #1d202b;
    margin-bottom: 10px;
}

</style>
<style>
.pay-methods .el-radio__label {
  color: #606266 !important;
}
</style>
